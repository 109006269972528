import React, { useState } from "react"; 
import './Header.css'
import {BiMenuAltRight} from 'react-icons/bi'


export default function Header(params) {
    const [menuop,setMenuop]=useState(false);
    const getmenu= (menuop)=>{
        if(document.documentElement.clientWidth<=800){
            return{ right: !menuop && "-100%"}
        }
    }
    return(
       <section className="h-wrap">
           <div className="flexCenter paddings innerWidth h-container">
              <div className="title">
                <div className="blue-circle"/>
               <h3 className="t-head">Nightstay</h3>
              </div>
               <div className="flexCenter h-menu"
                 style ={getmenu(menuop)} 
               >
                   <a href="">Hotels</a>
                   <a href="">Our value</a>
                                    
                   <a href="">Get started</a>
                   <a href="">Contact</a>
                   <button className="button">
                   <a href="">Contact us</a>
                   </button>
               </div>
               <div className="menu-icon" onClick={()=>setMenuop((prev)=>!prev)}>
                <BiMenuAltRight size={30}/>
           </div>
           </div>

          
       </section>
    );
};

