import React from "react";
import './Footer.css'

export default function Footer(params) {
    return(
        <section className="f-wrapper">
            <div className="paddings innerWidth flexCenter f-container">

                {/* left side */}
                {/* <div className="flexColCenter"> */}
                <div className="flexColStart f-left">
                <div className="title">
                
               <h2 className="t-head">Nightstay</h2>
              </div>
                   <span className="secondaryText">
                    Our vision is to make all people <br/>
                    the best place to live for them.
                   </span>
                </div>
    
        

            <div className="flexColStart f-right">
                 <span className="primaryText">Information</span>
                 <span className="secondaryText">145 Madurai</span>
         
            <div className="flexCenter f-menu">
                <span>property</span>
                <span>Services</span>
                <span>Product</span>
                <span>About us</span>
            </div>
         </div>
            </div>
        </section>
    )
};
