import React from "react";
import './Companies.css'

export default function Companies(params) {
    return(
        <section className="c-wrapper">
            <div className="paddings innerWidth flexCenter c-container">
                 <img src="./images/prologis.png" />
                 <img src="./images/tower.png" />
                 <img src="./images/equinix.png" />
                 <img src="./images/realty.png" />
                 
            </div>
        </section>
    )
};
