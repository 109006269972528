import Companies from "./components/Companies/Companies";
import Contacts from "./components/Contacts/Contacts";
import Footer from "./components/Footer/Footer";
import Getstarted from "./components/Getstarted/Getstarted";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Residencies from "./components/Residencies/Residencies";
import Value from "./components/Values/Value";

function App() {
  return (
    <div className="App">
      <div>
      <div className="white-gradient"></div>
      
      <Header/>
      <Hero/>
      </div>
    
      <Companies/>
      <Residencies/>
      <Value/>
      <Contacts/>
      <Getstarted/>
      <Footer/>      
      </div>
     
  );
}

export default App
