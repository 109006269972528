import React from "react";
import "./Hero.css";
import {HiLocationMarker} from 'react-icons/hi'
import CountUp from "react-countup";
import {motion} from 'framer-motion'
export default function Hero(params) {
    return(
        <section className="hero-wrapper">
            <div className="paddings innerWidth flexCenter hero-container">
              <div className="hero-left">
                 <div className="hero-title">
                        <div className="orange-circle"/>
                        <motion.h1
                        initial={{y: "2rem",opacity:0}}
                        animate={{y:0,opacity:1}}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        >Discover <br/>
                            Most Suitable<br/>
                             Hotel</motion.h1>
                     </div>
                     <div className="flexColStart hero-des">
                        <span>Find a variety of hotels that suit u very easily</span>
                        <span>forget all difficulties in finding a residence for you</span>
                     </div>
                     <br></br>
                     {/* searchbar */}
                     <div className="flexCenter search-bar">
                        <HiLocationMarker color="var(--blue)" size={25}/>
                        <input type="text"/>
                        <button className="button">Search</button>
                        </div>

                     <div className="flexCenter stats">
                        <div className="flexColCenter stat">
                               <span>
                                 <CountUp start={8000} end={9000} duration={3}/>
                                 <span>+</span>
                                </span>

                                <span>
                                    Premium Products
                                </span>
                           
                        </div>
                        <div className="flexColCenter stat">
                             <span>
                                 <CountUp start={1900} end={2000} duration={3}/>
                                 <span>+</span>
                             </span>

                                <span>
                                    Happy Customers
                                </span>
                        </div>
                        <div className="flexColCenter stat">
                              <span>
                                 <CountUp  end={28} />
                                 <span>+</span>
                             </span>

                                <span>
                                    Award winning
                                </span>
                        </div>


                     </div>
                    
              </div>

              
              <div className="flexCenter hero-right">
                 <motion.div className="img-container"
                 initial={{x:"7rem",opacity:0}}
                 animate={{x:0,opacity:1}}
                 transition={{
                    duration: 2,
                    type: "spring"
                }}
                 >
                    <img src="./images/hero-image.png" alt=""></img>
                 </motion.div>
              </div>
            </div>
        </section>
    )
};
